<template>
  <b-form-group :label="formLabel" :description="description">
    <b-input-group append="%">
      <template v-slot:prepend>
        <b-select v-if="prepend.length > 1"
                  v-model="increaseOrDecrease"
                  :options="prepend"
                  :disabled="disabled"
                  ref="select"
                  style="border-radius: .25rem 0 0 .25rem"
                  @change="updateValue(percentageValue)"
                  @focus="evaluateFocus(true)"
                  @blur="evaluateFocus(false)"/>
        <b-input-group-text v-else>{{prepend[0].text}}</b-input-group-text>
      </template>
      <b-form-input v-model="percentageValue"
                    :autofocus="autofocus"
                    :placeholder="placeholder"
                    ref="input"
                    :disabled="increaseOrDecrease === 0 || disabled"
                    :class="{ 'shrinky': shrinky }"
                    @focus="evaluateFocus(true)"
                    @blur="evaluateFocus(false)"
                    @change="updateValue($event)"
                    @keyup.enter="evaluateFocus(false)"/>
    </b-input-group>
  </b-form-group>
</template>

<script>
import { debounce } from 'lodash'
export default {
  name: 'bidAdjustmentInput',
  props: {
    value: {
      type: [Number, String]
    },
    prepend: {
      type: Array,
      default: () => {
        return [
          { text: 'Increase by', value: 1 },
          { text: 'Decrease by', value: -1 }
        ]
      }
    },
    description: String,
    adjustmentType: String,
    hideLabel: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'Value as a %'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    shrinky: {
      type: Boolean,
      default: false
    },
    allowNull: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      percentageValue: this.allowNull ? null : 0,
      increaseOrDecrease: 1
    }
  },
  watch: {
    value: {
      handler: function (newVal, oldVal) {
        if (newVal === 0 || !newVal) {
          this.percentageValue = this.allowNull ? null : 0
          if (this.prepend.some(v => v.text === 'Exclude' && v.value === 0)) {
            this.$setCompat(this, 'increaseOrDecrease', 0)
          }
        } else if (this.increaseOrDecrease !== 0) {
          this.percentageValue = (newVal - 1).toPrecision(4) * 100
          if (this.percentageValue < 0 && !this.increaseOnly) {
            this.percentageValue = Math.abs(this.percentageValue)
            this.$setCompat(this, 'increaseOrDecrease', -1)
          }
        }
      },
      immediate: true
    },
    prepend: {
      handler: function (newVal, oldVal) {
        // Check to see if there is an `Exclude` option set.
        // If there is AND this.value is zero, we will preselect that option.
        // This will disable the numeric input until the user changes the dropdown.
        if (this.value === 0 || !this.value) {
          this.percentageValue = this.allowNull ? null : 0
          if (newVal.some(v => v.text === 'Exclude' && v.value === 0)) {
            this.$setCompat(this, 'increaseOrDecrease', 0)
          }
        }
      }
    }
  },
  computed: {
    formLabel () {
      return (this.hideLabel) ? '' : 'Bid Adjustment'
    },
    increaseOnly () {
      return this.prepend.length === 1 && this.prepend[0].value === 1
    }
  },
  methods: {
    updateValue (newVal) {
      console.log('in updateValue')
      if (this.increaseOrDecrease === 0) {
        this.$emit('input', 0)
        return
      }
      if (newVal !== null) {
        let modifiedValue = Math.abs(newVal / 100)
        if (this.increaseOrDecrease > 0) {
          modifiedValue = 1 + modifiedValue
        } else if (this.increaseOrDecrease < 0) {
          if (modifiedValue > 0.9) { // making floor for bid modifier -90% -SNG
            modifiedValue = 1 - 0.9
          } else {
            modifiedValue = 1 - modifiedValue
          }
        }
        this.$emit('input', modifiedValue)
      }
    },
    evaluateFocus: debounce(function (val) {
      // I needed a blur event to come out of this component when focus leaves the input field AND the select field
      // but not when switching between the two. - JC
      if (!val) {
        this.$emit('blur')
      }
    }, 10)
  }
}
</script>

<style scoped>
  .shrinky {
    width: 60px;
  }
</style>
