<template>
  <span class="d-flex flex-wrap" :class="{ 'flex-column': shouldBadgify }">
    <span v-for="(row, rowKey) in rowsAndParts"
          :key="rowKey"
          class="d-block mb-1">
      <span v-for="(part, partKey) in row"
            :key="`${rowKey}-${partKey}`"
            class=""
            :class="[
              !shouldBadgify ? classes.nonBadgified : '',
              shouldBadgify ? classes.badgified : ''
            ]">
        <span :class="[
             shouldBadgify && isOnlyTag(part) ? classes.badgifiedTagOnly : '',
             shouldBadgify && isOnlyText(row) ? classes.badgifiedTextOnly : '',
             shouldBadgify && isOnlyText(row) && subtle ? 'text-black-60' : '',
             shouldBadgify && !isOnlyText(row) && !isOnlyTag(part) ? classes.badgifiedMixed : ''
           ]">
          <b-badge v-if="hasTag(part)"
                   :variant="isAlias(part) ? 'inverted-info' : 'info'"
                   class="madlib-badge badge-full mb-1 text-wrap text-left text-break"
                   :class="[shouldBadgify ? 'line-height-lg' : '']">
            <template v-if="isAlias(part)">@</template>{{stripOuterTagSyntax(part)}}
            <span v-if="canCreateAliases && isComplex(part)"
                  v-b-popover.hover.top="'Convert to Alias'"
                  class="ml-2 text-warning"
                  @click.stop.prevent="createDynamicTag(part)">
              <fluency-icon type="lightningBolt" />
            </span>
          </b-badge>
          <span v-else>{{part}}</span>
        </span>
      </span>
    </span>
  </span>
</template>

<script>
export default {
  name: 'madLibTaggify',
  props: {
    inputString: {
      type: [String, Number],
      default: ''
    },
    badgify: {
      type: Boolean,
      default: true
    },
    canCreateAliases: {
      type: Boolean,
      default: false
    },
    subtle: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      classes: {
        nonBadgified: 'mr-1',
        badgified: 'mb-2',
        badgifiedTagOnly: '',
        badgifiedTextOnly: 'badge badge-light badge-full border font-weight-normal text-wrap text-left line-height-lg',
        badgifiedMixed: 'text-wrap text-left line-height-lg'
      }
    }
  },
  computed: {
    rowsAndParts () {
      return this.solveRows(this.inputString)
    },
    shouldBadgify () {
      return this.badgify && typeof this.inputString === 'string' && this.inputString.indexOf('\n') >= 0
    },
    dynamicTagAliases () {
      return this.$store.getters.blueprintEditorCurrentDynamicTagAliases
    }
  },
  methods: {
    solveRows (str) {
      const result = []

      if (typeof str !== 'string') {
        result.push(this.solveParts(str))
        return result
      }

      const toLines = str.split('\n')

      toLines.forEach(line => {
        result.push(this.solveParts(line))
      })

      return result
    },
    solveParts (str) {
      const result = []
      const open = '['
      const close = ']'
      let stack = 0

      if (typeof str !== 'string') {
        result.push(str)
        return result
      }

      const splitArr = str.split('')
      let currentString = ''

      splitArr.forEach((char, ind) => {
        if (char === open) {
          if (stack === 0) {
            result.push(currentString)
            currentString = ''
          }
          stack++
          currentString += char
        } else if (char === close) {
          currentString += char
          if (stack === 1) {
            result.push(currentString)
            currentString = ''
          }
          stack--
        } else if (this.badgify && char === '\n') {
          result.push(currentString)
          currentString = ''
        } else {
          currentString += char
        }
      })

      result.push(currentString)

      return result.filter(phrase => { return phrase !== '' })
    },
    hasTag (input) {
      return typeof input === 'string' && input.indexOf('[') >= 0
    },
    isAlias (val) {
      if (this.isOnlyTag(val)) {
        const strippedVal = this.stripOuterTagSyntax(val)
        return this.dynamicTagAliases.includes(strippedVal)
      }

      return false
    },
    isComplex (val) {
      if (!this.canCreateAliases) {
        return false
      }

      if (val.indexOf('>>') >= 0) {
        return true
      }
      if (val.indexOf('||') >= 0) {
        return true
      }
      if (val.indexOf('::') >= 0) {
        return true
      }

      return false
    },
    isOnlyTag (val) {
      return val.startsWith('[') && val.endsWith(']')
    },
    isOnlyText (row) {
      return Array.isArray(row) && row.length === 1 && row[0].indexOf('[') === -1 && row[0].indexOf(']') === -1
    },
    stripOuterTagSyntax (str) {
      return str.slice(1, -1)
    },
    createDynamicTag (tag) {
      this.$eventBus.$emit('create-dynamic-tag-alias', tag)
    }
  }
}
</script>

<style scoped>

</style>
