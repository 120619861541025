<template>
  <div class="d-flex align-items-center flex-wrap"
       :class="{
         'cursor-disabled': disabled,
         'w-100 position-relative': block,
         'overflow-hidden': overflowEllipsis && !inputMode
        }">

    <span v-if="block && caret"
          class="position-absolute cursor-pointer"
          style="top: 4px; left: 100%; margin-left: -13px;"
          @click.stop="clickedTextView($event)">
      <fluency-icon v-if="caret && block"
                    type="caretDown"
                    size="xs" />
    </span>

    <template v-if="!singleInput && !hideBadges">
      <b-badge v-for="(v, i) in value" :key="`${v}${i}`" :variant="taggify && v.match(/[[\]]/g) ? 'info' : badgeVariant"
               class="d-flex align-items-center mr-3 my-2" :class="badgeClasses">
        <span v-if="taggify && v.match(/[[\]]/g)" class="mb-1 mr-3">{{v.replace(/[[\]]/g, '')}}</span>
        <span v-else-if="typeAheadLookup(v)" class="mb-1 mr-3">{{typeAheadLookup(v)}}</span>
        <span v-else class="mb-1 mr-3">{{formatText(v)}}</span>
        <span v-if="!disabled" class="cursor-pointer" @click="$emit('blur'), deleteValue(i)"><fluency-icon type="close"  /></span>
      </b-badge>
    </template>

    <t-button-inline v-if="!inputMode"
                     class="my-2 d-flex-center word-break-break-word text-left"
                     data-qa="madLibInput-activateButton"
                     :class="{
                      'px-3 position-relative flex-grow-1': block,
                      'block-madlib-dropdown-link': block && caret,
                      'border-bottom border-radius-0 not-block-mad-lib-link': !block,
                      'border-danger': error,
                      'overflow-hidden': overflowEllipsis
                     }"
                     @click.stop="clickedTextView($event)"
                     @keyup="checkForTabFocus($event)"
                     :style="[disabled ? 'pointer-events: none;' : undefined]">
      <fluency-icon v-if="disabled"
                    type="lock"
                    class="mr-2"
                    :class="[textClass]"/>

      <span :class="[{
          'white-space-pre-line': derivedFormat === 'TEXTAREA',
          'dropdown-toggle': caret && !block,
          'white-space-no-wrap overflow-hidden text-overflow-ellipsis text-left': (caret && block) || overflowEllipsis
        }, textClass]"
            data-qa="madLibInput-displayText">
        <span v-if="typeof value[0] === 'string' && onlySpaces(value[0])">{{placeholder}}</span>
        <span v-else-if="Number.isNaN(value[0])">{{placeholder}}</span>
        <template v-else-if="(typeof value[0] !== 'undefined' && value[0] !== null && value[0] !== '') && singleInput">
          <mad-lib-taggify v-if="taggify"
                           :input-string="typeAheadLookup(value[0]) || formatText(value[0])"
                           :badgify="badgify"
                           :subtle="subtle"
                           :can-create-aliases="canCreateAliases"/>
          <template v-else-if="typeAheadLookup(value[0])">
            <span v-if="typeAheadLookup(value[0]).includes('<')" v-html="typeAheadLookup(value[0])"></span>
            <template v-else>{{typeAheadLookup(value[0])}}</template>
          </template>
          <template v-else>{{formatText(value[0])}}</template>
        </template>

        <span v-else-if="taggify && taggifyPlaceholderText"
              :class="textClassPlaceholder"><mad-lib-taggify :input-string="placeholder" :badgify="badgify"
                                                             :subtle="subtle"/></span>
        <span v-else
              :class="textClassPlaceholder">{{placeholder}}</span>

      </span>

      <b v-if="characterLimit > 0" class="ml-1 text-danger">
        <span v-if="lengthViolation()">{{lengthError}}</span>
      </b>
    </t-button-inline>
    <template v-else-if="hasEditSlotContent">
      <slot name="edit"
            :value="text"
            :input="(val) => text = val"
            :change="(val) => text = val"
            :blur="(nativeEvent) => addValue(false, nativeEvent)"
            :keyupEnterNative="() => addValue(true)"></slot>
    </template>
    <template v-else>
      <div v-if="['TEXT', 'ENUM', 'NUMBER', 'TEXTAREA'].includes(derivedFormat)"
           :class="{ 'flex-grow-1': fillWidth }"
           class="position-relative">

        <b-input-group class="d-flex-center"
                       ref="dropdownInputGroup">

          <template #prepend>
            <slot name="prepend"></slot>
          </template>

          <div v-if="derivedFormat === 'TEXTAREA'"
               :class="{ 'flex-grow-1': fillWidth }"
               class="position-relative">
            <b-form-textarea :value="text"
                             :id="inputId"
                             ref="theInput"
                             data-qa="madLibInput-input-textarea"
                             autofocus
                             :autocomplete="isTypeAhead ? 'off' : ''"
                             :rows="derivedTextareaRows"
                             :style="(derivedTextareaRows === 1) ? 'height:32px;' : ''"
                             @input="text = $event; typeaheadInputEventHandler($event)"
                             @keydown.enter="handleEnterInTextarea($event)"
                             @blur="addValue(false, $event)"/>
          </div>
          <b-form-input v-else
                        :value="text"
                        :id="inputId"
                        ref="theInput"
                        data-qa="madLibInput-input"
                        autofocus
                        :autocomplete="isTypeAhead ? 'off' : ''"
                        :type="format === 'NUMBER' ? 'number' : 'text'"
                        @input="text = $event; typeaheadInputEventHandler($event)"
                        @blur="addValue(false, $event)"
                        @keyup.enter.prevent="addValue(true)"
                        :style="`height: ${height};`"/>

          <b v-if="characterLimit > 0"
             class="ml-3"
             :class="[lengthViolation() ? 'text-danger' : 'text-muted']">
            ({{(text) ? $filters.evaluateLengthAfterInsertion(text) : 0}} / {{characterLimit}})
          </b>

          <template #append>
            <slot name="append"></slot>
          </template>

          <div v-if="shownTypeAheadOptions.length > 0 || typeAheadLoading"
               class="position-absolute border dropdown-menu show" :class="{'dropdown-menu-right': dropDownRight}"
               style="max-height: 50vh; overflow-y: auto;">
            <fluency-loader v-if="typeAheadLoading" dots />
            <template v-else>
              <slot name="dropdownAppend"></slot>

              <template v-for="(categoryOptions, categoryKey) in categorizedTypeAheadOptions" :key="categoryKey">
                <b-dropdown-group :header="$filters.convertFromCapsUnderscore(categoryKey)" header-tag="h5">

                  <b-dropdown-item v-for="(option, index) in categoryOptions"
                                   :key="`${option}${index}`"
                                   class="type-ahead-option"
                                   :data-qa="`madLibInput-dropdownItem-${index}${categoryKey ? '-' + categoryKey : ''}`"
                                   :tabindex="index"
                                   :disabled="option.disabled"
                                   :data-type-ahead="(option.hasOwnProperty('value')) ? JSON.stringify(option) : null"
                                   :active="activeTypeAheadIndex === index && activeTypeAheadCategory === categoryKey"
                                   active-class="active">
                    <slot v-bind="{ option, index, categoryKey }" name="dropdownItemContent">
                      <div v-if="option.hasOwnProperty('html')" v-html="option.html" data-qa="madLibInput-dropdownItem-content"></div>
                      <span v-else-if="option.hasOwnProperty('text')" data-qa="madLibInput-dropdownItem-content">
                        {{option.text}}
                        <i v-if="!option.text">&mdash; none &mdash;</i>
                      </span>
                      <span  v-else-if="option.hasOwnProperty('value')" data-qa="madLibInput-dropdownItem-content">{{option.value}}</span>
                      <span v-else data-qa="madLibInput-dropdownItem-content">{{option}}</span>
                    </slot>
                  </b-dropdown-item>
                </b-dropdown-group>
              </template>
              <template v-if="typeAheadSlice < typeAheadOptions.length">
                <b-dropdown-divider/>
                <b-dropdown-item
                  :data-show-more="true"
                  size="sm"
                  variant="link"
                  tabindex="-1"
                  link-class="text-success"
                  @click.prevent.stop="showMoreTypeaheadOptions()">Show More</b-dropdown-item>
              </template>
            </template>
          </div>
        </b-input-group>

      </div>

      <bid-adjustment-input v-if="format === 'PERCENTAGE'"
                            class="mb-0"
                            v-model="text"
                            autofocus
                            allow-null
                            hide-label
                            placeholder=""
                            @blur="addValue()"
                            :style="`height: ${height};`"></bid-adjustment-input>

      <percentage-input v-if="format === 'BASIC_PERCENTAGE'"
                        v-model="text"
                        allow-null
                        autofocus
                        placeholder=""
                        @blur="addValue()"
                        :style="`height: ${height};`"></percentage-input>

      <currency-input v-if="format === 'CURRENCY'"
                      v-model="text"
                      autofocus
                      allow-null
                      placeholder=""
                      :min="minCurrencyValue"
                      @blur="addValue()"
                      @enter="addValue(true)"
                      :style="`height: ${height};`"></currency-input>

      <b-modal v-if="format === 'IMAGE'" visible size="med" centered :ok-disabled="imgPlaceholders.length === 0"
               @ok="handleImageInput($event)" @hidden="inputMode = false">
        <fluency-loader v-if="imageUploading" class="py-5 my-5"></fluency-loader>
        <div v-else class="d-flex flex-wrap align-items-center"
             :class="{'justify-content-center': singleInput || (imgPlaceholders.length === 0 && !text)}">
          <div class="p-2">
            <fluency-file-drop class="mt-5" :handler="handleImageSelect" allow-directories
                               style="height: 172px; width: 196px"></fluency-file-drop>
          </div>
          <div class="p-2" v-if="text">
            <div class="d-flex align-items-center justify-content-center mb-3"
                 style="height: 196px; width: 196px; background: black">
              <img :src="text" class="img-fluid mh-100 mw-100"/>
            </div>
            <div class="d-flex align-items-center">
            </div>
          </div>
          <div class="p-2" v-for="image in imgPlaceholders" :key="image.file.name">
            <div class="d-flex align-items-center justify-content-center mb-3"
                 style="height: 196px; width: 196px; background: black">
              <img :src="image.fileExchange.base64EncodedFileContents" class="img-fluid mh-100 mw-100"/>
            </div>
            <div class="d-flex align-items-center">
              <span>{{image.file.name}}</span>
            </div>
          </div>
        </div>
      </b-modal>

    </template>

  </div>
</template>

<script>
import CurrencyInput from 'core-ui/components/inputs/currencyInput'
import BidAdjustmentInput from 'core-ui/components/inputs/bidAdjustment'
import PercentageInput from 'core-ui/components/inputs/percentageInput'
import FluencyFileDrop from 'core-ui/components/inputs/fluencyFileDrop'
import FluencyLoader from 'core-ui/components/common/fluencyLoader'
import MadLibTaggify from 'core-ui/components/inputs/madLibTaggify'

export default {
  name: 'madLibInput',
  components: {
    MadLibTaggify,
    FluencyLoader,
    FluencyFileDrop,
    PercentageInput,
    BidAdjustmentInput,
    CurrencyInput
  },
  compatConfig: {
    INSTANCE_SCOPED_SLOTS: false
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: 'Enter a Value'
    },
    singleInput: {
      type: Boolean,
      default: false
    },
    format: {
      type: String,
      default: 'TEXT'
    },
    typeAheadOptions: {
      type: Array
    },
    disableAutoFocus: {
      type: Boolean,
      default: false
    },
    retainValueOnFocus: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: '27px;'
    },
    badgeClasses: {
      type: String,
      default: ''
    },
    badgeVariant: {
      type: String,
      default: 'secondary'
    },
    textVariant: {
      type: String
    },
    fillWidth: {
      type: Boolean,
      default: false
    },
    taggify: {
      type: Boolean,
      default: false
    },
    taggifyPlaceholderText: {
      type: Boolean,
      default: true
    },
    badgify: {
      type: Boolean,
      default: true
    },
    canCreateAliases: {
      type: Boolean,
      default: false
    },
    subtle: {
      type: Boolean,
      default: false
    },
    characterLimit: {
      type: Number,
      default: 0
    },
    error: {
      type: Boolean,
      default: false
    },
    lengthError: {
      type: String,
      default: ''
    },
    allowEmptyStrings: {
      type: Boolean,
      default: false
    },
    textareaRows: {
      type: Number,
      default: 5
    },
    disabled: {
      type: Boolean,
      default: false
    },
    customTextFormatter: {
      type: Function
    },
    requireOption: {
      type: Boolean,
      default: false
    },
    dropDownRight: {
      type: Boolean,
      default: false
    },
    inputId: {
      type: String
    },
    pagingSize: {
      type: Number,
      default: 50
    },
    typeAheadLoading: {
      type: Boolean,
      default: false
    },
    caret: {
      type: Boolean,
      default: false
    },
    block: {
      // show a MadLib in a b-form-group to look more like a traditional input (with possibility of using MadLib typeahead functionality)
      type: Boolean,
      default: false
    },
    hideBadges: {
      type: Boolean,
      default: false
    },
    overflowEllipsis: {
      type: Boolean,
      default: false
    },
    minCurrencyValue: {
      type: Number
    }
  },
  data () {
    return {
      inputMode: false,
      text: null,
      originalValue: null,
      imgPlaceholders: [],
      imageUploading: false,
      typeAheadSlice: 50,
      activeTypeAheadIndex: -1,
      activeTypeAheadCategory: null,
      typing: false,
      forceToTextarea: false,
      forceTextareaRows: -1
    }
  },
  mounted () {
    this.typeAheadSlice = this.pagingSize

    if (this.value.filter(v => v).length === 0 && !this.disableAutoFocus) {
      this.inputMode = true
    }
  },
  unmounted () {
    window.removeEventListener('keydown', this.typeAheadKeybindings)
  },
  watch: {
    inputMode (val) {
      if (val) {
        this.$emit('focus')
        if (this.isTypeAhead) {
          this.scrollToActive()
        }
      }
      this.setupTypeAheadKeybindings()
    },
    value: {
      immediate: true,
      handler (val) {
        if (this.retainValueOnFocus && this.singleInput && Array.isArray(val)) {
          this.text = this.typeAheadLookup(val[0]) || val[0]
          this.originalValue = this.typeAheadLookup(val[0]) || val[0]
        }
      }
    },
    shownTypeAheadOptions: {
      handler () {
        this.activeTypeAheadIndex = -1
      }
    }
  },
  computed: {
    isTypeAhead () {
      return this.typeAheadOptions && this.typeAheadOptions.length > 0
    },
    shownTypeAheadOptions () {
      if (!this.typeAheadOptions) return []
      if (!Array.isArray(this.typeAheadOptions)) return []
      if (!this.text) return this.typeAheadOptions.slice(0, this.typeAheadSlice)
      // const hasFullTextMatch = this.typeAheadOptions.some(opt => opt.text && opt.text === this.text)

      if (!this.typing) {
        const fullTextIndex = this.typeAheadOptions.findIndex(opt => (opt.text || opt) === this.text)
        const sliceIndex = fullTextIndex < this.typeAheadSlice ? 0 : fullTextIndex
        return this.typeAheadOptions.slice(sliceIndex, sliceIndex + this.typeAheadSlice)
      }
      return this.typeAheadOptions.filter(opt => {
        if (typeof opt === 'object') {
          if (!opt?.text) {
            return true
          }
          return opt.text.toLowerCase().includes(this.text.toString().toLowerCase()) ||
            `${opt?.value ?? ''}`.toLowerCase().includes(this.text.toString().toLowerCase())
        }
        return opt.toLowerCase().includes(this.text.toString().toLowerCase())
      }).slice(0, this.typeAheadSlice)
    },
    categorizedTypeAheadOptions () {
      return this.shownTypeAheadOptions.groupBy(opt => opt.category || '')
    },
    hasEditSlotContent () {
      if (this.$slots.edit && typeof this.$slots.edit === 'function') {
        const editSlotNodes = this.$slots.edit()
        return editSlotNodes?.length > 0
      }
      return false
    },
    textClass () {
      if (this.disabled) return 'text-disabled'
      if (this.block) return 'text-form-control'
      if (this.subtle) return 'text-black-50'
      if (this.textVariant) return `text-${this.textVariant}`
      return ''
    },
    textClassPlaceholder () {
      let textClass = 'text-muted'
      if (this.subtle) {
        textClass = 'text-more-muted'
      }
      return `font-italic ${textClass}`
    },
    derivedFormat () {
      if (this.format === 'TEXT' && this.forceToTextarea) {
        return 'TEXTAREA'
      }

      return this.format
    },
    derivedTextareaRows () {
      if (this.forceTextareaRows > 0) {
        return this.forceTextareaRows
      }
      return this.textareaRows
    }
  },
  methods: {
    onlySpaces (str) {
      return str.length > 0 && str.replace(/\s/g, '').length === 0
    },
    setupTypeAheadKeybindings () {
      if (this.inputMode) {
        window.addEventListener('keydown', this.typeAheadKeybindings)
      } else {
        this.setActiveTypeAheadIndex(-1, null)
        window.removeEventListener('keydown', this.typeAheadKeybindings)
      }
    },
    typeAheadKeybindings (e) {
      const watchedKeys = ['ArrowDown', 'ArrowRight', 'ArrowUp', 'ArrowLeft', 'Escape']

      if (watchedKeys.includes(e.key)) {
        switch (e.key) {
          case 'ArrayRight':
          case 'ArrowDown':
            this.incrementActiveTypeAheadIndex()
            break
          case 'ArrowLeft':
          case 'ArrowUp':
            this.decrementActiveTypeAheadIndex()
            break
          case 'Escape':
            this.setActiveTypeAheadIndex()
            break
        }
      }
    },
    incrementActiveTypeAheadIndex () {
      let val = this.activeTypeAheadIndex + 1
      let category = this.activeTypeAheadCategory !== null ? this.activeTypeAheadCategory : (this.shownTypeAheadOptions[0]?.category ?? '')
      if (!this.categorizedTypeAheadOptions) {
        this.setActiveTypeAheadIndex()
      }
      const categoryKeys = Object.keys(this.categorizedTypeAheadOptions)
      if (!categoryKeys || categoryKeys?.length === 0) {
        this.setActiveTypeAheadIndex()
      }
      if (!this.categorizedTypeAheadOptions[category]) {
        this.setActiveTypeAheadIndex()
      }
      if (val >= this.categorizedTypeAheadOptions[category]?.length) {
        const categoryIndex = categoryKeys.indexOf(category)
        if (categoryIndex + 1 >= categoryKeys?.length) {
          val = 0
          category = categoryKeys[0]
        } else {
          val = 0
          category = categoryKeys[categoryIndex + 1]
        }
      }
      this.setActiveTypeAheadIndex(val, category)
    },
    decrementActiveTypeAheadIndex () {
      let val = this.activeTypeAheadIndex - 1
      let category = this.activeTypeAheadCategory !== null ? this.activeTypeAheadCategory : (this.shownTypeAheadOptions[0]?.category ?? '')
      if (!this.categorizedTypeAheadOptions) {
        this.setActiveTypeAheadIndex()
      }
      const categoryKeys = Object.keys(this.categorizedTypeAheadOptions)
      if (!categoryKeys || categoryKeys?.length === 0) {
        this.setActiveTypeAheadIndex()
      }
      if (!this.categorizedTypeAheadOptions[category]) {
        this.setActiveTypeAheadIndex()
      }
      if (val < 0) {
        const categoryIndex = categoryKeys.indexOf(category)
        if (categoryIndex - 1 < 0) {
          category = categoryKeys[categoryKeys?.length - 1]
          val = this.categorizedTypeAheadOptions[category]?.length - 1
        } else {
          category = categoryKeys[categoryIndex - 1]
          val = this.categorizedTypeAheadOptions[category]?.length - 1
        }
      }
      this.setActiveTypeAheadIndex(val, category)
    },
    setActiveTypeAheadIndex (val = -1, category = null) {
      this.$setCompat(this, 'activeTypeAheadIndex', val)
      this.activeTypeAheadCategory = category
    },
    typeAheadLookup (val) {
      if (!this.typeAheadOptions) {
        return false
      }

      const opt = this.typeAheadOptions.find(tao => {
        return tao?.value === val
      })

      return opt?.text || opt?.html
    },
    lengthViolation () {
      if (this.characterLimit < 1) {
        return false
      }

      if (!this.text) {
        return false
      }

      if (this.containsTags()) {
        return false
      }

      return this.$filters.evaluateLengthAfterInsertion(this.text) > this.characterLimit
    },
    containsTags () {
      return typeof this.text === 'string' && this.text.indexOf('[') >= 0 && this.text.indexOf(']') >= 0
    },
    checkForTabFocus (e) {
      const key = e.keyCode ? e.keyCode : e.which
      if (key === 9) {
        this.clickedTextView(e)
      }
    },
    clickedTextView (el) {
      this.textToTextareaConversionCheck(el)

      if (!this.disabled) this.$setCompat(this, 'inputMode', true)
    },
    textToTextareaConversionCheck (el) {
      // This method will convert a normal text input to a textarea if the preview is multiline
      if (this.format !== 'TEXT') {
        return
      }

      const elHeight = el?.srcElement?.offsetHeight
      // const queriedLineHeight = window.getComputedStyle(el.srcElement, null).getPropertyValue('line-height')
      const singleLineHeight = 14
      const lineHeight = 18
      const firstToSecondLineHeightDiff = lineHeight - singleLineHeight

      this.$setCompat(this, 'forceToTextarea', false)
      this.$setCompat(this, 'forceTextareaRows', -1)

      if (elHeight) {
        const adjustedElHeight = (elHeight > singleLineHeight) ? elHeight - (firstToSecondLineHeightDiff) : singleLineHeight

        if (adjustedElHeight > lineHeight) {
          const numRows = Math.round(adjustedElHeight / lineHeight)

          this.$setCompat(this, 'forceToTextarea', true)
          this.$setCompat(this, 'forceTextareaRows', numRows)
        }
      }
    },
    handleEnterInTextarea (e) {
      // if this is a text area forced to a textarea we want to prevent the `ENTER` key from inserting a linebreak
      if (this.forceToTextarea) {
        e.preventDefault()
        this.addValue(false)
      }
    },
    async handleImageInput (bvModalEvent) {
      bvModalEvent.preventDefault()
      this.imageUploading = true
      const resp = await Promise.all(this.imgPlaceholders.map(img => this.uploadFile(img.file)))
      if (resp) {
        this.text = resp.map(img => img.location).join(',')
        this.addValue()
        this.imgPlaceholders = []
      }
      this.imageUploading = false
    },
    async handleImageSelect (file) {
      if (file.type.includes('image')) {
        const fileExchange = await this.readFile(file)
        if (this.singleInput) {
          this.text = ''
          this.imgPlaceholders = [{ file, fileExchange }]
        } else {
          this.imgPlaceholders.push({ file, fileExchange })
        }
      }
    },
    readFile (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.addEventListener('loadend', () => {
          resolve({
            base64EncodedFileContents: reader.result,
            sourceFileName: file.name,
            knownMimeType: file.type
          })
        })
        reader.addEventListener('onError', () => {
          reject(reader.error)
        })
        reader.readAsDataURL(file)
      })
    },
    async uploadFile (file) {
      const formData = new FormData()
      formData.append('file', file, file.name)
      const response = await this.$res.set.imageUpload(formData)
      return response
    },
    typeaheadInputEventHandler (currVal) {
      this.$emit('type', currVal)
      this.typing = true
    },
    showMoreTypeaheadOptions () {
      this.$setCompat(this, 'typeAheadSlice', this.typeAheadSlice + 50)
    },
    addValue (retainInputMode, nativeEvent) {
      if (this.activeTypeAheadIndex > -1 && this.shownTypeAheadOptions?.length > 0) {
        const option = this.categorizedTypeAheadOptions?.[this.activeTypeAheadCategory]?.[this.activeTypeAheadIndex]

        if (!option) {
          return
        }

        this.$emit('blur')
        this.$setCompat(this, 'inputMode', false)

        const val = option.hasOwnProperty('value') ? option.value : option

        if (this.retainValueOnFocus) {
          this.$setCompat(this, 'text', val)
        } else {
          this.text = null
        }

        const newVal = this.singleInput ? [val] : [...this.value, val]
        this.$emit('input', newVal)
        this.typing = false

        return
      }

      if (nativeEvent?.relatedTarget?.attributes?.['data-show-more']) {
        this.$refs.theInput.focus()
        return
      }
      this.$emit('blur')

      // TypeAheads w/ Bootstrap style option arrays: [{value,text}]
      if (nativeEvent?.relatedTarget?.attributes?.['data-type-ahead']) {
        const json = JSON.parse(nativeEvent?.relatedTarget?.attributes?.['data-type-ahead'].nodeValue)
        if (typeof json?.value !== 'undefined') {
          this.text = json.value
        } else {
          this.text = json?.text || ''
        }
      } else if (nativeEvent?.relatedTarget?.text && this.shownTypeAheadOptions.includes(nativeEvent.relatedTarget.text)) {
        this.text = nativeEvent.relatedTarget.text
      } else if (this.requireOption) {
        this.inputMode = false
        this.text = null
        return
      }
      this.typing = false
      if (this.retainValueOnFocus && this.text === this.originalValue) {
        this.inputMode = false
      } else {
        if (this.allowEmptyStrings || this.text || this.text === 0) {
          let newV = this.text
          if (this.format === 'NUMBER') newV = parseFloat(newV)
          newV = this.singleInput ? [newV] : [...this.value, ...(typeof newV === 'string' ? newV.split(',').map(v => v.trim()) : [newV])]

          this.$emit('input', newV)
          this.originalValue = newV
        }
        this.inputMode = false
        if (!this.retainValueOnFocus) {
          this.text = null
        }
        if (retainInputMode && !this.singleInput) {
          this.$nextTick(() => { this.inputMode = true })
        }
      }
    },
    deleteValue (i) {
      const v = [...this.value]
      v.splice(i, 1)
      this.$emit('input', v)
    },
    formatText (text) {
      if (this.customTextFormatter) {
        return this.customTextFormatter(text)
      }
      switch (this.format) {
        case 'PERCENTAGE':
          return text > 1 ? `increase by ${this.$filters.percentage(text - 1)}` : `decrease by ${this.$filters.percentage(1 - text)}`
        case 'BASIC_PERCENTAGE':
          return this.$filters.percentage(text)
        case 'CURRENCY':
          return this.$filters.currency(text, 2)
        case 'NUMBER':
          const decimal = typeof this.text === 'number' && this.text % 1 !== 0
          return this.$filters.number(text, decimal, true)
        case 'IMAGE':
          return this.$filters.truncate(text, 40, true)
        case 'ENUM':
          const option = this.typeAheadOptions.find(opt => (opt.value || opt) === text)
          if (option) {
            return option.text || option
          }
          break
      }
      return text
    },
    scrollToActive () {
      const vThis = this
      setTimeout(() => {
        const activeEl = vThis.$refs.dropdownInputGroup?.querySelector('a.dropdown-item.active')
        if (activeEl) {
          const menuEl = vThis.$refs.dropdownInputGroup.querySelector(('.dropdown-menu'))
          const index = vThis.findActiveOptionIndexByText(this.text) // vThis.shownTypeAheadOptions.findIndex(opt => (opt.text || opt) === (Array.isArray(this.text) ? this.text[0] : this.text))
          menuEl?.scrollTo(0, index * activeEl.offsetHeight)
        }
      }, 100)
    },
    findActiveOptionIndexByText (text) {
      const option = this.shownTypeAheadOptions.find(opt => (opt.text || opt) === (Array.isArray(text) ? text[0] : text))
      if (!option) return -1
      const categorizedOptionIndex = this.categorizedTypeAheadOptions[option.category || ''].findIndex(opt => (opt.value || opt) === (option.value || option))
      return categorizedOptionIndex
    }
  }
}
</script>

<style lang="scss" scoped>
.not-block-mad-lib-link:active {
  border: 0 !important;
}
</style>
